// src/components/Header.tsx
import React, { useEffect, useRef, useState, CSSProperties } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import logo from "./assets/Logo.png";

const HeaderContainer = styled.header<{ open: boolean; location: string }>`
  // display: flex;
  // justify-content: ${(props) =>
    props.open ? "space-between" : "flex-start"};
  // gap: 40%;
  margin: 0px 0.5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // gap: 0px;
  // justify-content: flex-start;
  padding: 0px 1.8rem;
  background-color: #333;
  color: white;
  max-height: 3rem;
  height: 3rem;
  @media (max-width: 768px) {
    // justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px 0rem;
    top: 1rem;
  }

  position: ${(props) => (props.location === "/" ? "fixed" : "absolute")};
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  margin: 0 auto 0 auto;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 0px;
  margin-bottom: 0px;
`;

const Logo = styled.div``;

const NavMenu = styled.nav<{ open: boolean }>`
  display: flex;
  gap: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;

    position: absolute;
    top: 0;
    right: -2rem;
    // height: 100%;
    // flex-grow: 1;
    // padding-bottom:2rem;
    width: 250px;
    background-color: #333;
    padding-top: 60px;
    transition: all 0.8s ease-in-out;

    display: ${(props) => (props.open ? "flex" : "none")};
    z-index: 1000;
    height: 100vh;
    top: -1rem;
  }
`;
// transform: ${(props) =>
//       props.open ? "translateX(0)" : "translateX(100%)"};
const NavItem = styled(Link)`
  text-decoration: none;
  color: white;
  font-size: 1.2rem;

  &:hover {
    color: #aaa;
  }
`;

const Hamburger = styled.div<{ open: boolean }>`
  display: none;
  cursor: pointer;
  z-index: 1100;
  height: 100%;
  text-align: right;
  padding: 0px 0.7rem;
  @media (max-width: 768px) {
    display: block;
    transition: transform 0.5s ease-in-out;
    transform: ${(props) =>
      props.open ? "translateY(0rem)" : "translateY(.7rem)"};
  }
`;
const DivSpace = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const styles = {
  logo: {
    objectFit: "contain",
    margin: "0px",
    padding: "0px 5px",
    height: "3rem",
    maxHeight: "3rem",
    textAlign: "left",
  } as CSSProperties,
};

const Header2: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  // const hamburgerRef = useRef<HTMLDivElement | null>(null);
  const sidebarRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target as Node)
      // &&
      // hamburgerRef.current &&
      // !hamburgerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <HeaderContainer open={isOpen} location={location.pathname}>
      <Logo style={styles.logo}>
        {/* <LogoIcon width={50} height={50} style={styles.logo} /> */}
        <Link to="/">
          <img src={logo} alt="Logo" className="logo" style={styles.logo} />
        </Link>
      </Logo>
      <Hamburger onClick={toggleMenu} open={isOpen}>
        {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </Hamburger>
      <NavMenu open={isOpen} ref={sidebarRef}>
        <NavItem to="/" onClick={toggleMenu}>
          Home
        </NavItem>
        <NavItem to="/about" onClick={toggleMenu}>
          About
        </NavItem>
        <NavItem to="/terms" onClick={toggleMenu}>
          Terms
        </NavItem>
        <NavItem to="/contact" onClick={toggleMenu}>
          Contact
        </NavItem>
        {/* <div className="search-box">
          <input type="text" placeholder="Search..." />
        </div> */}
      </NavMenu>
      <DivSpace></DivSpace>
    </HeaderContainer>
  );
};

export default Header2;
