// src/About.js
import React, { CSSProperties } from "react";
import TextContainer from "./TextContainer";
const About = () => {
  return (
    <TextContainer title="About US">
      <p>
        <b>Our Mission: </b> At FeelScope, our mission is to deliver the most
        accurate and comprehensive sentiment analysis derived from social data.
        We believe in the power of data to better understand public emotions and
        their impact on social and economic decisions.
      </p>
      <p>
        <b>Our Technology: </b> We utilize cutting-edge artificial intelligence
        and machine learning technologies to analyze massive volumes of text
        data. Our natural language processing (NLP) algorithms automatically
        extract emotions from social media content and translate them into
        actionable insights.
      </p>
      <p>
        <b>Our Team: </b> The FeelScope team consists of experts in data
        analytics, social sciences, and software development. With our
        experience and expertise in sentiment analysis, we are dedicated to
        developing solutions that enhance understanding of emotional trends
        across different geographical regions.
      </p>
      <p>
        <b>Our Vision:</b> We believe that sentiment analysis is a powerful tool
        for gaining deeper insights into societies and human behavior. Our
        ultimate goal is to provide valuable insights from emotional data that
        support smarter and more effective decision-making.
      </p>
    </TextContainer>
  );
};

export default About;
