import React, { useState, useEffect, CSSProperties } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
  GeographyProps,
  ProjectionConfig,
} from "react-simple-maps";
import Swal from "sweetalert2";

import io from "socket.io-client";
import Map from "./Map";
// import Country from "./Country";
import { getCountries } from "./api";
import { Country } from "./Country";
import ToolBar from "./ToolBar";
import * as allColors from "./colors";
import { ColorPalette } from "./type/ColorPalette";
import { IEmotion } from "./interface/IEmotion";
import { CustomGeographyProps } from "./interface/CustomGeographyProps";
import CustomGeography from "./CustomGeography";
import { Emotions } from "./enum/Emotions";

// const geoUrl = "/features.json";
const geoUrl = "https://unpkg.com/world-atlas@2.0.2/countries-110m.json";
// const socket = io("http://localhost:3000");

interface Position {
  coordinates: [number, number];
  zoom: number;
}

const MapChart: React.FC = () => {
  const [position, setPosition] = useState<Position>({
    coordinates: [0, 0],
    zoom: 1,
  });

  const [configProject, setConfigProject] = useState<ProjectionConfig>({
    center: [0, 0],
    scale: 110,
  });
  const getConfigProject = (): ProjectionConfig => {
    if (window.innerWidth <= 460)
      return {
        center: [40, 160],
        scale: 500,
      };
    else if (window.innerWidth <= 768)
      return {
        center: [40, 155],
        scale: 500,
      };
    else if (window.innerWidth <= 980)
      return {
        center: [50, 155],
        scale: 300,
      };
    else if (window.innerWidth <= 1240)
      return {
        center: [50, 160],
        scale: 250,
      };
    else if (window.innerWidth <= 1440)
      return {
        center: [20, 160],
        scale: 200,
      };
    else if (window.innerWidth <= 1740)
      return {
        center: [10, 160],
        scale: 170,
      };
    else
      return {
        center: [20, 20],
        scale: 140,
      };
  };
  useEffect(() => {
    const handleResize = () => {
      const config = getConfigProject();
      setConfigProject(config);
    };
    handleResize();
  }, []);

  function handleZoomIn() {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  }

  function handleZoomOut() {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  }

  function handleMoveEnd(position: Position) {
    setPosition(position);
  }

  // const handleCountryClick = (geo: CustomGeographyProps, name: string) => {
  //   // alert(`Country: ${geo.properties.name}`);
  //   // console.log(geo);
  //   // console.log({ id: geo.id, name });
  // };

  const getCountryData = (countryId: string): Country | null => {
    if (!stats || stats.length === 0) return null;
    const country = stats.find((i) => i.countryId == countryId);
    if (!country) return null;
    return country;
  };

  const [stats, setStats] = useState<Country[]>();
  // const [message, setMessage] = useState<string>("");
  // const [messages, setMessages] = useState<string[]>([]);
  //   const isMobile = useMediaQuery("(max-width:768px)");
  //   const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });

  //   const handleMoveEnd = (position) => {
  //     setPosition(position);
  //   };
  useEffect(() => {
    // socket.on("message", (message: string) => {
    //   setMessages((prevMessages) => [...prevMessages, message]);
    // });
    async function getData() {
      const countries = await getCountries();

      if (countries) {
        const countriesData = countries.map((i: any) => {
          return {
            countryId: i.id,
            countryName: i.name,
            stats: {
              angriness: i.angriness,
              happiness: i.happiness,
              sadness: i.sadness,
              panic: i.panic,
            },
          };
        });
        setStats(countriesData);
      }
    }

    try {
      getData();
    } catch (error) {
      // console.log(error);
    }

    return () => {
      // socket.off("message");
    };
  }, []);

  const [emotion, setEmotion] = useState<IEmotion | null>(null);

  // Callback function to handle data from child
  const handleEmotion = (data: IEmotion) => {
    setEmotion(data);
  };

  const getEmotionObj = (val: string): IEmotion => {
    if (val === "sadness")
      return { name: Emotions.sadness, color: allColors.sadnessColor };
    if (val === "happiness")
      return { name: Emotions.happiness, color: allColors.happinessColor };
    if (val === "angriness")
      return { name: Emotions.angriness, color: allColors.angrinessColor };
    if (val === "panic")
      return { name: Emotions.panic, color: allColors.panicColor };
    return { name: Emotions.notCalculated, color: allColors.defaultColor };
  };

  const higherEmotionColor = (country: Country): string => {
    let emoValue: number | null = null;
    let emotion: IEmotion = {
      name: Emotions.notCalculated,
      color: allColors.defaultColor,
    };
    Object.entries(country.stats).forEach(([key, value]) => {
      if (!emoValue || value > emoValue) {
        emotion = getEmotionObj(key);
        emoValue = value;
      }
    });
    // return allColors.defaultColor.main;
    return colorLevel(emoValue, emotion);
  };
  const colorLevel = (emo: number | null, emotion: IEmotion | null): string => {
    if (!emo || !emotion) return allColors.defaultColor.main;
    if (emo >= 60) return emotion.color.darken;
    if (emo >= 45) return emotion.color.dark;
    if (emo >= 30) return emotion.color.main;
    if (emo >= 15) return emotion.color.light;
    if (emo >= 0) return emotion.color.lighten;
    return allColors.defaultColor.main;
  };
  const setColor = (id: string): string => {
    const country = getCountryData(id);
    if (!country) return allColors.defaultColor.main;
    if (!emotion) return higherEmotionColor(country);
    // if (!emotion || !country?.stats.hasOwnProperty(emotion.name))
    // return defaultColor.main;

    let emo = null;
    Object.entries(country.stats).forEach(([key, value]) => {
      if (key === emotion.name) emo = value;
    });
    return colorLevel(emo, emotion);
    // if (!emo) return defaultColor.main;
    // if (emo >= 60) return emotion.color.darken;
    // if (emo >= 45) return emotion.color.dark;
    // if (emo >= 30) return emotion.color.main;
    // if (emo >= 15) return emotion.color.light;
    // if (emo >= 0) return emotion.color.lighten;
    // return defaultColor.main;
  };
  return (
    <div style={styles.home}>
      <ComposableMap
        projectionConfig={configProject}
        width={800}
        height={400}
        style={styles.page}
      >
        <ZoomableGroup
          zoom={position.zoom}
          center={position.coordinates}
          onMoveEnd={handleMoveEnd}
        >
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <CustomGeography
                  key={geo.rsmKey}
                  geography={geo}
                  // onClick={() => handleCountryClick(geo, geo.name)}
                  country={getCountryData(geo.id)}
                  countryId={geo.id}
                  countryName={geo.properties.name}
                  style={{
                    default: {
                      fill: setColor(geo.id),
                      outline: " none",
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <ToolBar onSelectEmotion={handleEmotion} />
      {/* <div className="controls">
        <button onClick={handleZoomIn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="12" y1="5" x2="12" y2="19" />
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
        <button onClick={handleZoomOut}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="3"
          >
            <line x1="5" y1="12" x2="19" y2="12" />
          </svg>
        </button>
      </div> */}
    </div>
  );
};

const styles = {
  page: {
    // textAlign: "center",
    // // width: "60vw",
    height: "100vh",
    // // backgroundColor: "#2c2d2d",
    // flex: 1,
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center",
  } as CSSProperties,

  home: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  } as CSSProperties,
};

export default MapChart;
