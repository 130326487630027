// src/Contact.js
import React, { CSSProperties } from "react";
import TextContainer from "./TextContainer";

const Contact = () => {
  return (
    <TextContainer title="Contact Us">
      <p>
        <b>Get in Touch:</b> For any questions, suggestions, or support
        requests, please reach out to us using the contact form below. The
        FeelScope support team will respond to your inquiry as soon as possible.
        <p>
          <b>Email:</b> info@feelscope.com
        </p>
        <p>
          <b>Support:</b> For technical support or inquiries related to
          sentiment data, please contact our technical support team at
          support@feelscope.com
        </p>
      </p>
    </TextContainer>
  );
};

export default Contact;
