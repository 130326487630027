import React, { CSSProperties, useState } from "react";
import styles from "./ToolBar.module.css";
import EmojiButton from "./EmojiButton";
import { IEmotion } from "./interface/IEmotion";
import { Emotions } from "./enum/Emotions";
import {
  angrinessColor,
  happinessColor,
  panicColor,
  sadnessColor,
} from "./colors";

interface ToolBarProps {
  onSelectEmotion: (emo: IEmotion) => void;
}

const ToolBar: React.FC<ToolBarProps> = ({ onSelectEmotion }) => {
  const [isSelected, setIsSelected] = useState<string | null>(null);

  const handleClick = (emo: IEmotion) => {
    // console.log({ emo, isSelected });
    setIsSelected(emo.name);
    onSelectEmotion(emo);
  };
  return (
    // <div className="glass-header" style={styles.header}>

    <div className={styles.glassToolbar}>
      <span className={styles.toolbarTitle}>Search with emoji :</span>
      <div className={styles.emojiList}>
        <EmojiButton
          emoji="😊"
          onClick={() =>
            handleClick({ name: Emotions.happiness, color: happinessColor })
          }
          label={Emotions.happiness}
          color={happinessColor.main}
          selected={Boolean(isSelected === Emotions.happiness)}
        />
        <EmojiButton
          emoji="😞"
          onClick={() =>
            handleClick({ name: Emotions.sadness, color: sadnessColor })
          }
          label={Emotions.sadness}
          color={sadnessColor.main}
          selected={isSelected === Emotions.sadness}
        />
        <EmojiButton
          emoji="😤"
          onClick={() =>
            handleClick({ name: Emotions.angriness, color: angrinessColor })
          }
          label={Emotions.angriness}
          color={angrinessColor.main}
          selected={isSelected === Emotions.angriness}
        />
        <EmojiButton
          emoji="😱"
          onClick={() =>
            handleClick({ name: Emotions.panic, color: panicColor })
          }
          label={Emotions.panic}
          color={panicColor.main}
          selected={isSelected === Emotions.panic}
        />
      </div>
    </div>
  );
};

// const styles = {
//   header: {
//     position: "sticky",
//     top: 0,
//     backgroundColor: "#333",
//     color: "white",
//     padding: "10px 0",
//     textAlign: "center",
//   } as CSSProperties,
// };

// const styles = {
//   // header: {
//   //   position: "sticky",
//   //   top: 0,
//   //   backgroundColor: "#333",
//   //   color: "white",
//   //   padding: "10px 0",
//   //   textAlign: "center",
//   // } as CSSProperties,
//   // link: {
//   //   margin: "0 10px",
//   //   color: "white",
//   //   textDecoration: "none",
//   // } as CSSProperties,
//   toolbarTitle: {
//     font: "4rem",
//     fontWeight: "600",
//   } as CSSProperties,
// };

export default ToolBar;
