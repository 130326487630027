// src/Contact.js
import React from "react";
import TextContainer from "./TextContainer";

const TermsOfUse = () => {
  return (
    <TextContainer title="Terms of Use">
      <p>
        <b>Introduction:</b> This document outlines the Terms of Use for the
        services provided by FeelScope. By accessing and using our services, you
        agree to comply with all the terms and conditions set forth in this
        agreement.
      </p>
      <p>
        <b>Data Collection and Analysis:</b> FeelScope utilizes advanced methods
        and machine learning algorithms to collect and analyze public data from
        social media platforms. The data includes text content shared by users
        across various social networks, which is automatically gathered and
        processed. This data is sourced directly through public APIs of social
        media platforms and then securely stored in FeelScope’s databases for
        sentiment analysis.
      </p>
      <p>
        <b>Privacy and Data Security:</b> We respect user privacy. The collected
        data is used solely in an anonymous form and only for aggregated
        sentiment analysis. FeelScope employs advanced encryption protocols to
        protect user data and prevent unauthorized access. No personal
        information is involved in the sentiment analysis process, and no data
        is identified at the individual level.
      </p>
      <p>
        <b>Limitation of Liability:</b> The sentiment data provided by FeelScope
        is intended for general informational purposes and should not be
        construed as legal, medical, or financial advice. FeelScope is not
        responsible for any decisions or actions taken based on the analysis
        provided.
      </p>
      <p>
        <b>Modifications:</b> We reserve the right to update these Terms of Use
        at any time without prior notice. It is your responsibility to
        periodically review this page to stay informed of any changes.
      </p>
    </TextContainer>
  );
};

export default TermsOfUse;
